function getConfig(domain) {
  const host = domain || window.location.host;
  return CONFIG[host] || {};
}

const CONFIG = {
  "localhost:3000": {
    api_url: "http://localhost:9000",
    project_name: "LOCAL",
    profit_share: 0.5,
    //partner_cpc: 0.02,
    docs: {
      show_ping: true,
      // show_ping_classification: true
    },
    features: ["sms"],
  },
};

if (process?.env?.NODE_ENV === "development") {
  CONFIG["localhost:3000"].features = ["sms", "email"];
}

CONFIG["fjobs.co"] = {
  ...CONFIG["localhost:3000"],
  api_url: "https://fjobs.co",
  project_name: "FJOBS",
  docs: {
    show_ping: true,
    show_ping_classification: true,
  },
};

// CONFIG["pjobs.co"] = {
//   ...CONFIG["localhost:3000"],
//   api_url: "https://pjobs.co",
//   profit_share: 0.6,
//   project_name: "PJOBS",
// };
//
// CONFIG["zjobs.co"] = {
//   ...CONFIG["localhost:3000"],
//   api_url: "https://zjobs.co",
//   project_name: "ZJOBS",
// };

CONFIG["djobs.co"] = {
  ...CONFIG["localhost:3000"],
  api_url: "https://djobs.co",
  project_name: "DJOBS",
  docs: {
    show_ping: true,
    show_ping_classification: true,
  },
};

// CONFIG["gjobs.co"] = {
//   ...CONFIG["localhost:3000"],
//   api_url: "https://gjobs.co",
//   project_name: "GJOBS",
// };

CONFIG["rjobs.co"] = {
  ...CONFIG["localhost:3000"],
  api_url: "https://rjobs.co",
  project_name: "RJOBS",
  features: ["sms", "email"],
};

// CONFIG["kjobs.co"] = {
//   ...CONFIG["localhost:3000"],
//   api_url: "https://kjobs.co",
//   project_name: "KJOBS",
//   features: ["sms", "email"],
//   docs: {
//     show_ping: true,
//     show_ping_classification: true,
//   },
// };

// CONFIG["jgjob.co"] = {
//   ...CONFIG["localhost:3000"],
//   api_url: "https://jgjob.co",
//   project_name: "JGJOB",
// };

// CONFIG["qujob.co"] = {
//   ...CONFIG["localhost:3000"],
//   api_url: "https://qujob.co",
//   project_name: "QUJOB",
//   profit_share: 1,
// };

CONFIG["jobqe.co"] = {
  ...CONFIG["localhost:3000"],
  api_url: "https://jobqe.co",
  project_name: "JOBQE",
  profit_share: 1,
};

CONFIG["jobwc.co"] = {
  ...CONFIG["localhost:3000"],
  api_url: "https://jobwc.co",
  project_name: "JOBWC",
};

// CONFIG["hirem.co"] = {
//   ...CONFIG["localhost:3000"],
//   api_url: "https://hirem.co",
//   project_name: "HIREM",
//   profit_share: 1,
// };

CONFIG["ajobb.co"] = {
  ...CONFIG["localhost:3000"],
  api_url: "https://ajobb.co",
  project_name: "AJOBB",
  docs: {
    show_ping: true,
    show_ping_classification: true,
  },
};

CONFIG["job-u.co"] = {
  ...CONFIG["localhost:3000"],
  api_url: "https://job-u.co",
  project_name: "JOBU",
  features: ["sms", "email"],
  docs: {
    show_ping: true,
    show_ping_classification: true,
  },
};

// CONFIG["jobll.co"] = {
//   ...CONFIG["localhost:3000"],
//   api_url: "https://jobll.co",
//   project_name: "JOBLL",
// };
//
// CONFIG["jobrs.co"] = {
//   ...CONFIG["localhost:3000"],
//   api_url: "https://jobrs.co",
//   project_name: "JOBRS",
// };

CONFIG["lcjob.co"] = {
  ...CONFIG["localhost:3000"],
  api_url: "https://lcjob.co",
  project_name: "LCJOB",
  docs: {
    show_ping: true,
    show_ping_classification: true,
  },
};

// CONFIG["bjobu.co"] = {
//   ...CONFIG["localhost:3000"],
//   api_url: "https://bjobu.co",
//   project_name: "BJOBU",
//   docs: {
//     show_ping: true,
//     show_ping_classification: true,
//   },
// };

// CONFIG["jobsl.co"] = {
//   ...CONFIG["localhost:3000"],
//   api_url: "https://jobsl.co",
//   project_name: "JOBSL",
//   docs: {
//     show_ping: true,
//     show_ping_classification: true,
//   },
// };

CONFIG["ejmj.co"] = {
  ...CONFIG["localhost:3000"],
  api_url: "https://ejmj.co",
  project_name: "EJMJ",
  features: ["sms", "email"],
};

CONFIG["wjobss.co"] = {
  ...CONFIG["localhost:3000"],
  api_url: "https://wjobss.co",
  project_name: "WJOBSS",
};

// CONFIG["f-cdl.co"] = {
//   ...CONFIG["localhost:3000"],
//   api_url: "https://f-cdl.co",
//   project_name: "FCDL",
// };

CONFIG["jobmm.co"] = {
  ...CONFIG["localhost:3000"],
  api_url: "https://jobmm.co",
  project_name: "JOBMM",
  features: ["sms", "email"],
};

// CONFIG["flexj.co"] = {
//   ...CONFIG["localhost:3000"],
//   api_url: "https://flexj.co",
//   project_name: "FLEXJ",
// };

export default getConfig;
